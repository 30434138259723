import React,{ useState } from "react"
import { Box, Text } from "@chakra-ui/react"
import { navigate } from "gatsby"
import { isBrowser, PostData } from '../utils/signuplogin.js';


const StripeSuccessPage = ({ location, data, path }) =>{
  const [responseText, setResponseText] = useState("");

  if(isBrowser){
    const urlParams = new URLSearchParams(window.location.search);
    const sessionId = urlParams.get('session_id');

    const stripe_success = (sessionId) => {
      if(sessionId){
        var data = {
          session_id:sessionId,
        }
        PostData('stripe_success',data).then( (result) => {
          let responseData = result.data;
          if(responseData.error){
            setResponseText(JSON.stringify(responseData.error.text));
            return;
          }
          window.sessionStorage.setItem('userData',JSON.stringify(responseData.userData));
          window.sessionStorage.setItem('token',JSON.stringify(responseData.token));
          setResponseText("OK");
          navigate("/");
          return;
        });
      }
      else
      {
        setResponseText("入力してください");
        return;
      }
    }

    stripe_success(sessionId);


    return (
      <>
      {/* {console.log(customer)} */}
      {/* <p>sessionId = {sessionId}</p> */}
      <p>購入成功ありがとう</p>
      <Box>
        <Text>
          <pre>
          {responseText}
          </pre>
        </Text>
      </Box>
      </>
    );
  }else{
    return (
      <>
      <p>購</p>
      </>
    );
  }
}

// const StripeSuccessPage = isBrowser ? StripeSuccessPage0():{};
export default StripeSuccessPage